import React from "react";
import { connect } from "react-redux";
import {
  fetchSensorTypes,
  fetchSensorCompany,
} from "../../../../redux/actions/sensorTypes.action";
import { fetchFirmwareTypes } from "../../../../redux/actions/firmwareTypes.action";
import axios from "../../../../axios";
import Papa from "papaparse";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Select from "react-select";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import _ from "lodash";

import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import MaterialIcon, { colorPalette } from "material-icons-react";
import Badge from "@material-ui/core/Badge";
import Typography from "@material-ui/core/Typography";
import QRCode from "qrcode.react";
import readXlsxFile from "read-excel-file";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  FormGroup,
  Col,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";

// core components
import Header from "components/Headers/Header.jsx";
import { array } from "prop-types";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Helper from "../../../../helper";
import Switch  from "@material-ui/core/Switch";

const input = document.getElementById("input");
const { SearchBar } = Search;
let mountTypes = [
  {
    key: 0,
    label: "Vertical",
    value: "vertical",
  },
  {
    key: 1,
    label: "Horizontal",
    value: "horizontal",
  },
  {
    key: 2,
    label: "Axial",
    value: "axial",
  },
];

let axis = [
  {
    key: 0,
    label: "X",
    value: "x",
  },
  {
    key: 1,
    label: "Y",
    value: "y",
  },
  {
    key: 2,
    label: "Z",
    value: "z",
  },
];

const options = {
  showTotal: true,
};

const errorColumns = [
  {
    dataField: "sensorId",
    text: "sensorId",
    formatter: (cell, row) => {
      return (
        <span>
          <div>{cell.split(":")[0]}</div>
          <div style={{ color: "red" }}>
            <b>{cell.split(":")[1]}</b>
          </div>
        </span>
      );
    },
  },
  {
    dataField: "firmwareTypeId",
    text: "firmwareTypeId",
    formatter: (cell, row) => {
      return (
        <span>
          <div>{cell.split(":")[0]}</div>
          <div style={{ color: "red" }}>
            <b>{cell.split(":")[1]}</b>
          </div>
        </span>
      );
    },
  },
  {
    dataField: "sensorTypeId",
    text: "sensorTypeId",
    formatter: (cell, row) => {
      return (
        <span>
          <div>{cell.split(":")[0]}</div>
          <div style={{ color: "red" }}>
            <b>{cell.split(":")[1]}</b>
          </div>
        </span>
      );
    },
  },
];

let companyId = "";
let sensorId = "";
let is = [];

class Sensors extends React.Component {
  constructor(props) {
    super(props);

    let t = !Helper.isAuth() ? this.jump("/auth/login") : null;
  }

  state = {
    sensors: null,
    sensorUnit: ["vibration", "ultrasonic", "temperature", "humidity"],
    list: true,
    sensor: {},
    sensorTypes: [],
    firmware: [],
    tagOptions: [],
    //Navigations
    currentPage: 0,
    saving: false,
    pages: [
      "pageSensors",
      "pageSensorAdd",
      "pageSensorConfig",
      "pageProcessConfig",
      "pageMountConfig",
    ],
    defaultSensorType: 0,
    columns: [
      {
        text: "Sensor Id",
        dataField: "sensorId",
        sort: true,
      },
      {
        text: "",
        dataField: "sensorId",
        formatter: this.copyIcon,
      },
      {
        text: "QR Code",
        dataField: "sensorId",
        sort: true,
        formatter: this.qrCodes,
      },
      {
        text: "Tag ID",
        dataField: "tagId",
        sort: true,
      },
      {
        text: "Sensor Type",
        dataField: "sensorTypeId",
        sort: true,
        // events: {
        //   onClick: (e, column, columnIndex, row, rowIndex) => {
        //     console.log("row:", row);
        //     this.jump(
        //       "/v1/companies/" + companyId + "/sensors/" + row.sensorId,
        //       { sensorType: true, sensorId: row.sensorId }
        //     );
        //   },
        // },
      },
      {
        text: "Firmware Type",
        dataField: "firmwareVersId",
        sort: true,
        // events: {
        //   onClick: (e, column, columnIndex, row, rowIndex) => {
        //     this.jump(
        //       "/v1/companies/" + companyId + "/sensors/" + row.sensorId,
        //       {
        //         firmwareType: true,
        //         firmwareId: row.configMap[0].firmwareConfig.firmwareType,
        //       }
        //     );
        //   },
        // },
      },

      {
        text: "Action",
        dataField: "sensorId",
        sort: false,
        formatter: this.action,
        events: {
          onClick: async (e, column, columnIndex, row, rowIndex) => {
            // if (row.tagId) {
            //   let details = await axios.configTypeDetails(row.tagId);
            //   if (details.data.data.isDIYConfig) {
            //     toast.error("Already assigned DIY config!");
            //     return;
            //   }
            // }
            if (row.config && row.config.length) {
              if (row.config.length < 2) {
                if (row.config[0].firmwareConfig) {
                  //here
                  this.jump(
                    "/v1/companies/" + companyId + "/sensors/" + row.sensorId
                  );
                } else {
                  this.jump(
                    "/v1/companies/" + companyId + "/sensors/o/" + row.sensorId
                  );
                }
              } else {
                this.jump(
                  "/v1/companies/" +
                    companyId +
                    "/sensors/" +
                    row.sensorId +
                    "/tags"
                );
              }
            } else if (row.tagId) {
              this.jump(
                "/v1/companies/" + companyId + "/sensors/" + row.sensorId,
                { tagId: row.tagId }
              );
            } else {
              let permission = {
                showDropDown: false,
                showSensDropDown: false,
                showFrimwareDropDown: false,
              };

              if (
                row.MDSensorTypeConfig &&
                row.MDSensorTypeConfig.length === 0
              ) {
                permission.showDropDown = true;
                permission.showSensDropDown = true;
              }

              if (
                row.MDfirmwareTypeConfig &&
                row.MDfirmwareTypeConfig.length === 0
              ) {
                permission.showDropDown = true;
                permission.showFrimwareDropDown = true;
              }
              if (permission.showDropDown) {
                this.jump(
                  "/v1/assignConfig/" + companyId + "/" + row.sensorId,
                  permission
                );
              } else {
                this.jump(
                  "/v1/companies/" +
                    companyId +
                    "/sensors/restore/" +
                    row.sensorId,
                  { sensorTypeId: row.sensorTypeId }
                );
              }
            }

            // this.jump('/v1/companies/' + companyId + '/machines/' + machineId + '/equipments/' +  row.equipmentId + '/components/' + row.componentId, 0);
          },
        },
      },
      {
        text: "Delete",
        dataField: "sensorId",
        sort: false,
        formatter: this.delete,
        events: {
          onClick: (e, column, columnIndex, row, rowIndex) => {
            const isAssigned = row.tagId ? true : false;
            if (isAssigned) return toast.warn("Unassign the sensor to remove");

            this.removeSensor(row.sensorId);
          },
        },
      },
    ],
    csvfile: undefined,
    modal: false,
    modalAssign: false,
    numberOfRowsManual: [{}],
    isUpload: true,
    firmwareTypes: [],
    sensorTypes: [],
    formatDetailsModal: false,
    uploadError: false,
    failureArray: [],
    successArray: [],
    randomKey: "one",
    external: []
  };

  action(cell, row) {
    let _this = this;
    return <MaterialIcon icon="open_in_new" color="#0288d1"></MaterialIcon>;
  }

  delete(cell, row) {
    let _this = this;
    return <MaterialIcon icon="delete" color="red" />;
  }

  copyIcon(cell, row) {
    let _this = this;
    return (
      <span>
        {" "}
        <CopyToClipboard text={row.sensorId} onCopy={() => toast("Copied!!!")}>
          <MaterialIcon icon="content_copy" color="#0288d1" />
        </CopyToClipboard>
      </span>
    );
  }

  qrCodes(cell, row) {
    return (
      <QRCode
        id={cell}
        style={{ height: 60, width: 60 }}
        value={"Nano_" + cell}
        includeMargin={true}
      />
    );
  }

  removeSensor = async (sensorId) => {
    if (window.confirm("Are You sure you want to delete this sensor?")) {
      if (window.confirm("please confirm!")) {
        const response = await axios.sensorManage_Delete(sensorId);

        if (response.status === 200)
          toast.success("Sensor deleted successfully");
        else toast.error("Fail to delete sensor");
        // await this.getSensors();
        // await this.getMDSensor();

        await this.props.fetchSensorCompany(companyId);
      } else return;
    } else return;
  };

  downloadQR = () => {
    const canvas = document.getElementById("20000c2a690eabcd");
    const pngUrl = canvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    console.log(pngUrl);
    // let downloadLink = document.createElement("a");
    // downloadLink.href = pngUrl;
    // downloadLink.download = "20000c2a690eabcd.png";
    // document.body.appendChild(downloadLink);
    // downloadLink.click();
    // document.body.removeChild(downloadLink);
  };

  tagDisplay(cell, row) {
    console.log(cell, row);
    let tagIds = [];
    let tags = row.config.map((config) => {
      let s = {};

      // if (config.firmwareConfig) {
      //   s['color'] = '#3f51b5'
      // } else {
      //   s['color'] = 'red'
      // }

      return <div style={s}>{config.tagId}</div>;

      // return config.tagId;
    });
    console.log("tags::", tags);
    return tags;
  }

  strong(cell, row) {
    return (
      // <MaterialIcon icon="open_in_new" color='#0288d1'/>
      cell
    );
  }

  async componentDidMount() {
    companyId = this.props.match.params.companyId;
    sensorId = this.props.match.params.sensorId
      ? this.props.match.params.sensorId
      : null;

    await this.props.fetchSensorCompany(companyId);
    this.props.fetchFirmwareTypes();
    this.props.fetchSensorTypes();
  }

  jump(url, passVal = {}) {
    this.props.history.push(url, passVal);
  }

  handleChange = (event) => {
    this.setState({
      csvfile: event.target.files[0],
      isUpload: false,
    });
  };

  importCSV = () => {
    const { csvfile } = this.state;
    Papa.parse(csvfile, {
      complete: this.updateData,
      header: true,
      skipEmptyLines: true,
    });
  };

  getFirmwareTypes = async () => {
    let api = await axios.getFirmwareTypes();
    this.setState({ firmware: api.data.data });
  };

  validateDataEntered = (dataToValidate) => {
    // return
    let successArray = [],
      failureArray = [];
    dataToValidate.map((val, index) => {
      let foundsSensor = false,
        foundFirmware = true,
        foundSensorId = true;
      for (var i = 0; i < index; i++) {
        if (dataToValidate[i].sensorId == val.sensorId) {
          foundsSensor = true;
          val["sensorAlreadyPresent"] = true;
          val.sensorId = `${val.sensorId}: (sensorId already exist in the file)`;
        }
      }

      if (val.sensorId.length !== 16) {
        foundsSensor = true;
        val["sixteenCharacter"] = true;
      }

      if (foundsSensor) {
        if (val.sensorAlreadyPresent) {
          val.sensorId = val.sensorId;
        } else if (val.sixteenCharacter) {
          val.sensorId = `${val.sensorId}: (sensorId length should be 16 characters)`;
        } else {
          val.sensorId = val.sensorId;
        }
      }
      for (var i = 0; i < this.state.firmwareTypes.length; i++) {
        if (this.state.firmwareTypes[i]._id === val.firmwareTypeId) {
          foundFirmware = false;
        }
      }

      if (foundFirmware) {
        val["firmwareTypeAlreadyPresent"] = true;
        val.firmwareTypeId = `${val.firmwareTypeId}: (invalid firmwareTypeId)`;
      }

      for (var i = 0; i < this.state.sensorTypes.length; i++) {
        if (this.state.sensorTypes[i]._id === val.sensorTypeId) {
          foundSensorId = false;
        }
      }

      if (foundSensorId) {
        val["sensorTypesAlreadyPresent"] = true;
        val.sensorTypeId = `${val.sensorTypeId}: (invalid sensorTypeId)`;
        // failureArray.push(val);
      }

      if (foundsSensor || foundFirmware || foundSensorId) {
        failureArray.push(val);
      } else {
        successArray.push(val);
      }
    });

    return {
      successArray,
      failureArray,
    };
  };

  updateData = async (result) => {
    let formatCheck = false;
    for (let i = 0; i < result.data.length; i++) {
      const element = result.data[i];
      if (
        element.hasOwnProperty("firmwareTypeId") &&
        element.hasOwnProperty("sensorId") &&
        element.hasOwnProperty("sensorTypeId") &&
        element.hasOwnProperty("external") &&
        element.hasOwnProperty("source") 
      ) {
        //do nothing
      } else {
        formatCheck = true;
      }
    }

    if (formatCheck) {
      alert("Wrong format!!! please click on I icon for format info");
      return;
    }

    let erroExternalIDSource = false
    result.data.map(ele=>{
      if(!ele.external){
        erroExternalIDSource=true
        toast.warn("External is a required field")
      } 
      if((ele.external).toLowerCase()==='true' && ele.source===''){
        erroExternalIDSource=true
        toast.warn("Source is a required field")
      }
    })
    if(erroExternalIDSource) return;

    const validData = this.validateDataEntered(result.data);

    if (validData.failureArray.length > 0) {
      this.setState({
        uploadError: true,
        formatDetailsModal: true,
        failureArray: validData.failureArray,
      });
    }

    if (validData.successArray.length > 0) {
      let data = validData.successArray;
      data = data.map((val) => {
        return {
          sensorId: val.sensorId,
          sensorTypeId: val.sensorTypeId,
          companyId: companyId,
          firmwareVersId: val.firmwareTypeId,
          external: val.external.toLowerCase()==='true'? true : false,
          source: val.source ? val.source : null
        };
      });
      this.setState({ isUpload: true });
      let resultFinal = await axios.sensorManage_Create({
        sensors: data,
      });
      console.log("resultFinal::", resultFinal);
      if (resultFinal && resultFinal.status === 200) {
        data.map((val) => {
          toast.success(`${val.sensorId} has been added`);
        });
      } else if (
        resultFinal &&
        resultFinal.status === 400 &&
        resultFinal.data &&
        resultFinal.data.data
      ) {
        if (resultFinal.data.data.length >= 1) {
          resultFinal.data.data.map((data) => {
            toast.error(`${data.slice(0, 48)}` + `${data.slice(86, 103)}`);
          });
        } else {
          toast.error(`${resultFinal.data.data}`);
        }
      } else if (resultFinal &&resultFinal.status === 207) {
        if (resultFinal.data.data.failed.length > 0) {
          resultFinal.data.data.failed.map((data) => {
            toast.error(`${data.slice(0, 48)}` + `${data.slice(86, 103)}`);
          });
        }
      }

      await this.props.fetchSensorCompany(companyId);

      this.setState({ randomKey: Math.random().toString(36) });
    }

    // window.location.reload();
  };

  onAddSensorManual = async () => {
    const { numberOfRowsManual } = this.state;

    const getElementValueById = (id) => document.getElementById(id).value;

    const finalPush = [];
    let sourceMandatoryError = false
    numberOfRowsManual.forEach((v, i) => {
      const sensorId = getElementValueById(`${i}_input-sensorId`);
      const firmwareTypeId = getElementValueById(`${i}_input-firmwareTypeId`);
      const sensorTypeId = getElementValueById(`${i}_input-sensorTypeId`);
      const external = this.state.external[i] || false;
      const sourceValue = getElementValueById(`${i}_input-source`);
      const source = sourceValue ? sourceValue : null
      if(!source && external){
        sourceMandatoryError=true
      }
      finalPush.push({ firmwareTypeId, sensorId, sensorTypeId, external, source });
    });
    
    if(sourceMandatoryError){
      return toast.warn('Source is a required field')
    }
    const finalDataPush = await this.validateDataEntered(finalPush);

    if (finalDataPush.failureArray.length > 0) {
      this.setState({
        uploadError: true,
        formatDetailsModal: true,
        failureArray: finalDataPush.failureArray,
      });
    }

    if (finalDataPush.successArray.length > 0) {
      let response = await Promise.all(
        finalDataPush.successArray.map(
          ({ firmwareTypeId: firmwareVersId, ...data }) =>
            axios.sensorManage_Create({
              sensors: [{ ...data, companyId, firmwareVersId }],
            })
        )
      );

      response.forEach((val) => {
        const sensorId = val&& val.data && val.data.data[0].sensorId;
        if (val && val.status === 200)
          return toast.success(`${sensorId} has been added`);
        toast.error(`Existing record found with the same sensorId`);
      });

      this.setState({ numberOfRowsManual: [{}], modal: false });

      await this.props.fetchSensorCompany(companyId);
    }
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    let firmwareTypes = [],
      sensorTypes = [],
      sensors = [];
    if (nextProps.firmwareTypesData !== prevState.firmwareTypesData) {
      if (nextProps.firmwareTypesData) {
        firmwareTypes = nextProps.firmwareTypesData.data;
      }
    }
    if (nextProps.sensorCompanyData !== prevState.sensorCompanyData) {
      if (nextProps.sensorCompanyData) {
        sensors = nextProps.sensorCompanyData.data;
      }
    }

    if (nextProps.sensorTypesData !== prevState.sensorTypesData) {
      if (nextProps.sensorTypesData) {
        sensorTypes = nextProps.sensorTypesData.data;
      }
    }

    return {
      firmwareTypes,
      sensors,
      sensorTypes,
    };
  }

  render() {
    // const rowEvents = {
    //   onClick: (e, row, rowIndex) => {
    //     if (row.configMap.length) {
    //       if (row.configMap.length < 2) {
    //         if (row.configMap[0].firmwareConfig) {
    //           this.jump('/v1/companies/' + companyId + '/sensors/' + row.sensorId);
    //         } else {
    //           this.jump('/v1/companies/' + companyId + '/sensors/o/' + row.sensorId);
    //         }
    //       } else {
    //         this.jump('/v1/companies/' + companyId + '/sensors/' + row.sensorId + '/tags');
    //       }
    //     } else {
    //       this.jump('/v1/companies/' + companyId + '/sensors/restore/' + row.sensorId);
    //     }
    //   }
    // };

    return (
      <>
        <ToastContainer position="top-left" />
        <Header />
        {/* Page content */}
        <Container className="mt--7" fluid>
          <Row>
            <Col lg="6">
              <div
                onClick={() => {
                  this.jump("/v1/companies/" + companyId, 0);
                }}
                style={{ width: "2rem", height: "2rem", cursor: "pointer" }}
                className="icon custom-icon icon-shape bg-orange text-white rounded-circle shadow"
              >
                <i
                  style={{ fontSize: "25px" }}
                  className="fa fa-arrow-left"
                  aria-hidden="true"
                ></i>
              </div>
              {/* <a onClick={this.downloadQR}> Download QR </a> */}

              {this.state.currentPage < 1 && (
                <>
                  <Button
                    color="success"
                    onClick={() => {
                      this.props.history.push(
                        "/v1/companies/" + companyId + "/sensors/add"
                      );
                    }}
                    size="md"
                    // className='add-btn'
                  >
                    Configure Sensor
                  </Button>

                  {/* <Button
                    color='info'
                    style={{ background: "orange", border: "#FF5722" }}
                    onClick={() => {
                      this.props.history.push(
                        "/v1/companies/" + companyId + "/sensors/add/qr"
                      );
                    }}
                    size='md'
                  // className='add-btn'
                  >
                    Add Sensor
                  </Button> */}
                </>
              )}
            </Col>
            <Col lg="6 table-caption">
              <h1>Sensors</h1>
            </Col>
          </Row>

          {/* Table */}
          <Row>
            <div className="col">
              <Card className="bg-secondary shadow">
                {this.state.sensors && this.state.currentPage < 1 && (
                  <ToolkitProvider
                    keyField="_id"
                    data={this.state.sensors}
                    columns={this.state.columns}
                    search={{ searchFormatted: true }}
                  >
                    {(props) => (
                      <div>
                        <h3>
                          {" "}
                          Add Sensors{" "}
                          <MaterialIcon
                            icon="info"
                            color="#0288d1"
                            onClick={() => {
                              this.setState({ formatDetailsModal: true });
                            }}
                          />{" "}
                        </h3>
                        <span>
                          {/* <MaterialIcon icon="delete" color='#ff0000' /> */}
                          <label> Upload CSV file : </label>
                          <input
                            className="csv-input"
                            type="file"
                            ref={(input) => {
                              this.filesInput = input;
                            }}
                            accept=".csv"
                            name="file"
                            placeholder={null}
                            key={this.state.randomKey}
                            onChange={this.handleChange}
                          />
                          <Button
                            disabled={this.state.isUpload}
                            onClick={this.importCSV}
                          >
                            {" "}
                            Upload now!
                          </Button>

                          <label>Enter Manual Data: </label>
                          <Button
                            onClick={() => {
                              this.setState({ modal: true });
                            }}
                          >
                            {" "}
                            Manual
                          </Button>
                          {/* <Button
                            onClick={() => {
                              this.setState({ modalAssign: true });
                            }}
                          >
                            {" "}
                            Assign Sensor
                          </Button> */}
                        </span>
                        <SearchBar {...props.searchProps} />
                        <BootstrapTable
                          {...props.baseProps}
                          // rowEvents={ rowEvents }
                          pagination={paginationFactory(options)}
                          striped
                          hover
                        />
                      </div>
                    )}
                  </ToolkitProvider>
                )}
              </Card>
            </div>
          </Row>

          <Modal
            className="modal-styles"
            isOpen={this.state.modal}
            size="lg"
            toggle={() => {
              this.setState({
                modal: !this.state.modal,
                numberOfRowsManual: [{}],
              });
            }}
          >
            {/* <ModalHeader toggle={() => { this.setState({ modal: !this.state.modal }) }}>Sensor Details(Make sure there no empty rows or field)</ModalHeader> */}
            <ModalBody>
              <Row>
                <Col xs="12">
                  <span>
                    <h3>Sensor Details</h3>
                    <h5>(Make sure there no empty rows or field) </h5>
                  </span>
                </Col>{" "}
                <br />
                <br />
              </Row>
              {this.state.numberOfRowsManual.map((v, i) => {
                return (
                  <Row>
                    <Col xs="4">
                      <label
                        className="form-control-label"
                        htmlFor="input-first-name"
                      >
                        Add sensorId{" "}
                        <span className="text-danger">
                          <Input
                            style={{ height: "40px" }}
                            className="form-control-alternative"
                            id={i + "_input-sensorId"}
                            type="text"
                          />
                        </span>
                      </label>
                    </Col>
                    <Col xs="4">
                      <label
                        className="form-control-label"
                        htmlFor="input-first-name"
                      >
                        Add firmwareTypeId{" "}
                        <span className="text-danger">
                          <Input
                            style={{ height: "40px" }}
                            className="form-control-alternative"
                            id={i + "_input-firmwareTypeId"}
                            type="text"
                          />
                        </span>
                      </label>
                    </Col>
                    <Col xs="4">
                      <label
                        className="form-control-label"
                        htmlFor="input-first-name"
                      >
                        Add sensorTypeId{" "}
                        <span className="text-danger">
                          <Input
                            style={{ height: "40px" }}
                            className="form-control-alternative"
                            id={i + "_input-sensorTypeId"}
                            type="text"
                          />
                        </span>
                      </label>
                    </Col>
                    <Col xs="4">
                      <label
                        className="form-control-label"
                        htmlFor="input-first-name"
                      >
                        External{" "}
                        <span className="text-danger">
                            <Switch
                              checked={this.state.external[i] || false}
                              onChange={()=>{let externalIds=this.state.external
                                externalIds[i] = externalIds.length-1 <= i ? !externalIds[i] : true
                                if(externalIds[i]===false){
                                  document.getElementById(i + "_input-source").value="";
                                }
                                this.setState({external:externalIds})
                              }}
                              name="checkedB"
                              color="primary"
                              id={i + "_input-external"}
                            />
                        </span>
                      </label>
                    </Col>
                    <Col xs="4">
                      <label
                        className="form-control-label"
                        htmlFor="input-first-name"
                      >
                        Source{" "}
                        <span className="text-danger">
                          <Input
                            style={{ height: "40px" }}
                            className="form-control-alternative"
                            id={i + "_input-source"}
                            type="text"
                            disabled={!this.state.external[i]}
                          />
                        </span>
                      </label>
                    </Col>
                  </Row>
                );
              })}
              <Row style={{ paddingTop: "10px" }}>
                <Col xs={{ size: 2, offset: 10 }}>
                  <Button
                    onClick={() => {
                      this.setState({
                        numberOfRowsManual: [
                          ...this.state.numberOfRowsManual,
                          {},
                        ],
                      });
                    }}
                    color="success"
                  >
                    Add Row
                  </Button>
                </Col>
              </Row>
            </ModalBody>
            <ModalFooter>
              <Button onClick={this.onAddSensorManual} color="success">
                Add Final
              </Button>
              <Button
                onClick={() => {
                  this.setState({ numberOfRowsManual: [{}], modal: false });
                }}
                color="primary"
              >
                Cancel
              </Button>
            </ModalFooter>
          </Modal>

          <Modal
            className="modal-styles"
            isOpen={this.state.formatDetailsModal}
            size="lg"
            toggle={() => {
              this.setState({
                formatDetailsModal: !this.state.formatDetailsModal,
                uploadError: false,
              });
            }}
          >
            <ModalHeader
              toggle={() => {
                this.setState({
                  formatDetailsModal: !this.state.formatDetailsModal,
                  uploadError: false,
                });
              }}
            >
              {this.state.uploadError ? (
                <h3> Error </h3>
              ) : (
                <h3>Format Details</h3>
              )}{" "}
            </ModalHeader>
            <ModalBody>
              {this.state.uploadError ? (
                <div>
                  <BootstrapTable
                    keyField="sensorId"
                    data={this.state.failureArray}
                    columns={errorColumns}
                  />
                </div>
              ) : (
                <span>
                  <label>Names of the headers should be :</label>
                  <div>
                    <span>
                      <label>
                        sensorId, firmwareTypeId, sensorTypeId, external and source and should
                        arranged as shown in image below. Also please make sure
                        there are no empty rows
                      </label>
                    </span>
                    <br />
                    <span>
                      <CopyToClipboard
                        text={"sensorId, firmwareTypeId, sensorTypeId, external, source"}
                        onCopy={() => toast("Copied!!!")}
                      >
                        <a>Click Here to copy</a>
                      </CopyToClipboard>
                    </span>
                  </div>
                  <div>
                    <img
                      alt={"loading..."}
                      src={
                        "https://npsc-static.s3-us-west-2.amazonaws.com/uploadCSVformat.PNG"
                      }
                    />
                  </div>
                </span>
              )}
            </ModalBody>
          </Modal>

          <Modal
            className="modal-styles"
            isOpen={this.state.modalAssign}
            size="lg"
            toggle={() => {
              this.setState({ modalAssign: !this.state.modalAssign });
            }}
          >
            <ModalHeader
              toggle={() => {
                this.setState({ modalAssign: !this.state.modalAssign });
              }}
            >
              <h3>Assign TagId</h3>
            </ModalHeader>
            <ModalBody>
              <Row>
                <Col lg="4">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="input-first-name"
                    >
                      Tag Id
                    </label>
                    <Select
                      isClearable={false}
                      // onChange={this.handleSelectChange("sensor", "tagId")}
                      defaultValue={
                        this.state.tagOptions[this.state.defaultTagId]
                      }
                      options={this.state.tagOptions}
                    />
                  </FormGroup>
                </Col>
                <Col lg="4">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="input-first-name"
                    >
                      SensorId
                    </label>
                    <Select
                      isClearable={false}
                      // onChange={this.handleSelectChange("sensor", "tagId")}
                      defaultValue={
                        this.state.tagOptions[this.state.defaultTagId]
                      }
                      options={this.state.tagOptions}
                    />
                  </FormGroup>
                </Col>
                <Col lg="4">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="input-first-name"
                    >
                      Mout Type
                    </label>
                    <Select
                      isClearable={false}
                      // onChange={this.handleSelectChange("sensor", "tagId")}
                      defaultValue={
                        this.state.tagOptions[this.state.defaultTagId]
                      }
                      options={this.state.tagOptions}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </ModalBody>
          </Modal>
        </Container>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  firmwareTypesData: _.isEmpty(state.firmwareTypes.firmwareTypesData)
    ? null
    : state.firmwareTypes.firmwareTypesData,

  sensorCompanyData: _.isEmpty(state.sensorTypes.sensorCompanyData)
    ? null
    : state.sensorTypes.sensorCompanyData,

  sensorTypesData: _.isEmpty(state.sensorTypes.sensorTypesData)
    ? null
    : state.sensorTypes.sensorTypesData,

  sensorData: _.isEmpty(state.sensorTypes.sensorData)
    ? null
    : state.sensorTypes.sensorData,
});

const mapDispatchToProps = (dispatch) => {
  return {
    fetchSensorTypes: () => dispatch(fetchSensorTypes()),
    fetchFirmwareTypes: () => dispatch(fetchFirmwareTypes()),
    fetchSensorCompany: (companyId) => dispatch(fetchSensorCompany(companyId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Sensors);
