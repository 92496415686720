import React from "react";
import { connect } from "react-redux";
import _ from "lodash";
import MaterialIcon, { colorPalette } from "material-icons-react";
import { fetchCompanies } from "../../../redux/actions/companies.action";
import { fetchOrganizationsList } from "../../../redux/actions/organization.action";
import { fetchNotificationCodes } from "../../../redux/actions/notification.action";
import axios from "../../../axios";
import Papa from "papaparse";
import Select from "react-select";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as yup from "yup";
import Info from "@material-ui/icons/Info";
import Tooltip from "@material-ui/core/Tooltip";
import Switch from "@material-ui/core/Switch";
import { withStyles } from "@material-ui/core/styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  FormGroup,
  Col,
  Input,
} from "reactstrap";

// core components
import Header from "components/Headers/Header.jsx";
import Helper from "../../../helper";
import validation from "./validations";

const { SearchBar } = Search;

const options = {
  showTotal: true,
};

const IOSSwitch = withStyles((theme) => ({
  root: {
    width: 42,
    height: 26,
    padding: 0,
    margin: theme.spacing(1),
  },
  switchBase: {
    padding: 1,
    "&$checked": {
      transform: "translateX(16px)",
      color: theme.palette.common.white,
      "& + $track": {
        backgroundColor: "#52d869",
        opacity: 1,
        border: "none",
      },
    },
    "&$focusVisible $thumb": {
      color: "#52d869",
      border: "6px solid #fff",
    },
  },
  thumb: {
    width: 24,
    height: 24,
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.grey[50],
    opacity: 1,
    transition: theme.transitions.create(["background-color", "border"]),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

class Organizations extends React.Component {
  constructor(props) {
    super(props);
    let t = !Helper.isAuth() ? this.jump("/auth/login") : null;
  }

  state = {
    organizationList: null,
    list: true,
    chek: [],
    open: false,
    defaultTimezone: "",
    company: {
      organizationName: null,
      organizationId: null,
      externalId: null,
    },

    saving: false,
    columns: [
      {
        text: "Organization Name",
        dataField: "customName",
        sort: true,
      },
      {
        text: "Organization Id",
        dataField: "organizationId",
        sort: true,
      },
      {
        text: "Delete",
        dataField: "organizationId",
        formatter: (row) => {
          return <MaterialIcon icon="delete" color="red" />;
        },
      },
    ],
    csvfile: undefined,
    isUpload: true,
    demoOpen: false,
    demoOpen2: false,
    users: [],
    directMessageOptions:[],
  };

  strong(cell, row) {
    let length = cell.length;
    let trim = length > 35 ? cell.substr(0, 35) + "......" : cell;
    return trim;
  }
  async componentDidMount() {
    this.props.fetchOrganizationsList();
    let  directMessageOptions= await this.props.fetchNotificationCodes('customer');
    directMessageOptions = directMessageOptions
    this.setState({directMessageOptions : directMessageOptions})
  }



  handleChange = (input) => (e) => {
    let company = { ...this.state.company };
    company[input] = e.target.value;
    this.setState({ company: company });
  };

  async handleSave() {
    this.setState({ saving: true });

    //Validating
    let err = await validation.validate(this.state.company);
    if (err) {
      let message = "Organization name is required field";
      let message2 = "Please enter a valid input";
      this.setState({ saving: false });
      if (this.state.company.organizationName == null)
        return toast.warn(message);
      else {
        return toast.warn(message2);
      }
    }

    let api = await axios.organization_Create({
      customName: this.state.company.organizationName,
      externalId: this.state.company.externalId,
    });
    this.setState({ saving: false });
    if (api && api.status == 200) {
      toast.success("Organization saved successfully !");
      // this.props.history.goBack();
      // this.jump(
      //   "/v1/organizationdetail/" + api.data &&
      //     api.data.data &&
      //     api.data.data.organizationId,
      //   1000
      // );
      let selectedDMCodes = this.state.directMessageOptions
      await axios.organizationConfig_create({
        organizationId:
          api.data && api.data.data && api.data.data.organizationId,
        timezone: null,
        notificationToBeChecked: false,
        dashboard_link: "https://nanoprecisedataservices.com/",
        hyperlink_link: "https://nanoprecisedataservices.com/",
        defaultLang: {
          key: "defaultLang",
          langType: "eng",
          label: "English",
        },
        secondLang: {
          key: "secondLang",
          langType: "eng",
          label: "English",
        },
        sensorHealthDashboard: false,
        exportDataToPtc: false,
        healthSummaryDashboard: false,
        energyEfficiency: false,
        soundHPF: true,
        S3ForStaticData: true,
        overallrms: false,
        unit: "metric",
        dataExport: {
          active: false,
          url: "https://cloud.stg.az.relayr.io",
          key: "",
        },
        maintenanceLog: false,
        thresholdSharing: {
          active: false,
          keyList: [],
        },
        dailyConsolidatedAlarm: {
          active: false,
          directMessageCodes: selectedDMCodes && selectedDMCodes.length ? Helper.createDirectMessageJSON(selectedDMCodes) :{},
          numberOfBatchNotificationPerDay: 1,
          includeDirectNotification: true,
          smsNotification: true,
        },
        idleTimeConfig: {
          type: "lastRunning",
          rangeColor: [
            {
              idleDuration: "0-30",
              color: "#7ada2c",
            },
            {
              idleDuration: "31-60",
              color: "#f0c82a",
            },
            {
              idleDuration: "61-90",
              color: "#f57c00",
            },
            {
              idleDuration: ">90",
              color: "#fa0011",
            },
            {
              idleDuration: "No Data",
              color: "#BDBDBD",
            },
          ],
        },
        notificationConfig: {
          batteryNotification: false,
          batteryNotificationThreshold: 2.5,
        },
        s3Export: {
          active: false,
          bucket: "",
        },
        sensorStatsFrequencyInHours: 96,
        vib_rms_unit: "acc",
        vib_spectrum_domain: "time",
        vib_spectrum_unit: "vel",
        vib_waterfall_domain: "order",
        vib_waterfall_unit: "vel",
        vib_waveform_domain: "envelope",
        vib_waveform_unit: "dis",
        vibSpecCpmRange: "55000",
        vibSpecDenoisedRange: "1000",
        vibSpecDomainValue: "cycle",
        vibSpecEnvFreqRange: "1000",
        vibSpecEnvOrdRange: "100",
        vibSpecFreqDomainRange: "1000",
        vibSpecOrderDomainRange: "100",
        vibSpecRange: "80000",
        temperature: "C",
        intenseNanoAIMeasurement: true,
        dynamicDashboardetails: { isDynamicDashboardDetails: false },

        cmms: {
          active: true,
          authentication: {
            authenticationURL:
              "/oauth2/v2/token/accesstoken?grant_type=client_credentials",
            clientId: null,
            clientSecret: null,
            password: "Rj2IBjSMK1wVC0nI",
            token: null,
            type: "Bearer Auth",
            username: "KdtJPeP16iQL4eA1ccs7A6elFzeSy7nH",
          },
          connectionInfo: {
            authenticationURL:
              "/oauth2/v2/token/accesstoken?grant_type=client_credentials",
            baseParam: {
              lean: 1.0,
            },
            baseURL: "https://api.dev.cardinalhealth.com",
            updateURL: "/<workorderid>",
            workOderEndpoint: "/maximo/v1/workorders",
            workOrderGetParams: {
              select:
                "wonum,workorderid,assetnum,location,description_longdescription,wopriority",
              where:
                "historyflag=0 and istask=0 and status!='COMP' and reportedby='sa-nanoprecise.maxim' and woclass='WORKORDER' and assetnum='<assetnum>' and siteid='<siteid>' and cv03='<cv03>'",
            },
          },
          connectionType: "maximo",
          externalIdParamName: "assetnum",
          notificationPriority: {
            edge: "low",
            fault: "extreme",
            "nanoai-amp": "high",
            "nanoai-stat": "medium",
            sensor: "low",
            user: "low",
          },
        },
        lastRunningDiffLastCheckedTime: 1661799908,
        navigationOptionVisible: true,
        menuOptionVisible: true
      });
      window.location.reload(true);
      this.props.fetchOrganizationsList();
    } else if (api && api.status == 400) {
      toast.warn(api.data.data);
    } else if (api && api.status === 500) {
      toast.error("Something went wrong. Please try again.");
    }
  }

  jump(url, delay = 0) {
    setTimeout(() => {
      this.props.history.push(url);
    }, delay);
  }

  handleDemoCompanies = async (event) => {
    let { demoOpen, company } = this.state;
    demoOpen = event.target.checked;
    company.config.demo = event.target.checked;
    this.setState({ demoOpen, company });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  handleDemoClose = () => {
    let { company } = this.state;
    company.config.demo = !company.config.demo;
    this.setState({ demoOpen: false, csvfile: null, isUpload: false, company });
  };
  handleDemoSuccess = () => {
    let { company } = this.state;
    company.config.demo = true;
    this.setState({ demoOpen: false, isUpload: true, company });
  };

  handleFileInput = (event) => {
    this.setState({
      csvfile: event.target.files[0],
      isUpload: false,
    });
  };

  validateDataEntered = (dataToValidate) => {
    // console.log("validateeee",dataToValidate)
    // console.log("stateeeee",this.state.users)
    let successArray = [];
    //  let successArray = _.differenceBy(dataToValidate, this.state.users, 'userName')

    dataToValidate.map((val) => {
      let foundUserName = false,
        foundEmail = false;
      for (var i = 0; i < this.state.users.length; i++) {
        if (
          this.state.users.length &&
          this.state.users[i].userName === val.userName
        ) {
          foundUserName = true;
        }
        if (
          this.state.users.length &&
          this.state.users[i].email === val.email
        ) {
          foundEmail = true;
        }
      }

      if (foundUserName === true) {
        alert(`${val.userName} already exist`);
      }
      if (foundEmail === true) {
        alert(`${val.email} already exist`);
      }
      if (foundUserName === false && foundEmail === false) {
        let userGroupId = 2000;
        val["companyId"] = this.state.company.companyId;
        val["userGroupId"] = userGroupId;
        successArray.push(val);
      }
    });
    return { successArray };
  };

  updateData = async (result) => {
    let formatCheck = false;
    result.data.forEach((item) => {
      if (item.hasOwnProperty("userName") && item.hasOwnProperty("email"))
        return;
      formatCheck = true;
    });

    if (formatCheck) {
      alert(
        "Wrong format!!! please upload a right format use header userName and email"
      );
      return;
    }

    const data = this.validateDataEntered(result.data);

    //const data = result.data
    if (data.successArray.length > 0) {
      let validData = data.successArray;
      let usersList = {
        validData,
        companyId: this.state.company.companyId,
        bulkUsers: true,
      };
      this.setState({ usersList: usersList, demoOpen: false });
    }
  };

  importCSV = () => {
    if (!this.state.csvfile) {
      alert("Please upload file");
    }
    const { csvfile } = this.state;
    Papa.parse(csvfile, {
      complete: this.updateData,
      header: true,
    });
  };

  handleSkip = () => {
    let { company } = this.state;
    company.config.demo = company.config.demo;
    this.setState({ demoOpen: false, isUpload: false, company });
  };

  toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  handleSelectChange(e) {
    // console.log(e.value, "logdata");
    let { company } = this.state;

    if (e.value === "plantEquipmentComponent") {
      company.Layout = 0;
    } else {
      company.Layout = 1;
    }
    this.setState({ company });
  }

  handleTimeZoneSelect = (option) => {
    let company = { ...this.state.company };
    company.timezone = option.value;
    this.setState({
      company,
      defaultTimezone: option.key,
    });
  };

  handleS3ForStaticDataActive = async (event) => {
    let company = { ...this.state.company };
    company.config.S3ForStaticData = !company.config.S3ForStaticData;
    this.setState({ company });
  };

  handleOverAllRmsActive = async (event) => {
    let company = { ...this.state.company };
    company.config.overallrms = !company.config.overallrms;
    this.setState({ company });
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.organizationList !== prevState.organizationList) {
      if (nextProps.organizationList) {
        return {
          organizationList: nextProps.organizationList.data,
        };
      }
    }
  }

  render() {
    const rowEvents = {
      onClick: async (e, row, rowIndex) => {
        if (e.target.outerHTML.includes("delete")) {
          if (window.confirm("Do you really want to delete it?")) {
            await axios.organization_Delete(row.organizationId);
            toast.success("Organization deleted successfully !");
            this.props.fetchOrganizationsList();
          }
          return;
        }
        localStorage.setItem("organizationId", row.organizationId);
        this.jump("/v1/organizationdetail/" + row.organizationId);
      },
    };

    return (
      <>
        <ToastContainer position="top-left" />

        <Header />
        {/* Page content */}
        <Container className="mt--7" fluid>
          <Row>
            <Col lg="6">
              {this.state.list && (
                <Button
                  color="success"
                  onClick={() => {
                    this.setState({ list: false });
                  }}
                  size="md"
                  className="add-btn"
                >
                  Add Organization
                </Button>
              )}
            </Col>
            <Col lg="6 table-caption">
              <h1>Organizations</h1>
            </Col>
          </Row>

          {/* Table */}
          <Row>
            <div className="col">
              <Card className="bg-secondary shadow">
                {!this.state.list && (
                  <CardHeader className="border-0">
                    <h3 className="mb-0">Add Organization</h3>
                  </CardHeader>
                )}
                {this.state.organizationList && this.state.list && (
                  <ToolkitProvider
                    keyField="_id"
                    data={this.state.organizationList}
                    columns={this.state.columns}
                    search
                  >
                    {(props) => (
                      <div>
                        <SearchBar {...props.searchProps} />
                        <BootstrapTable
                          {...props.baseProps}
                          rowEvents={rowEvents}
                          keyField="_id"
                          data={this.state.organizationList}
                          columns={this.state.columns}
                          pagination={paginationFactory(options)}
                          striped
                          hover
                        />
                      </div>
                    )}
                  </ToolkitProvider>
                )}

                {!this.state.list && (
                  <CardBody>
                    <div className="pl-lg-4">
                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-username"
                            >
                              Organization Name{" "}
                              <span className="text-danger">*</span>
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-username"
                              type="text"
                              valid={false}
                              onChange={this.handleChange("organizationName")}
                              disabled={this.state.readOnly}
                            />
                          </FormGroup>
                        </Col>

                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-last-name"
                            >
                              External ID{" "}
                              <Tooltip
                                title="This is the ID used by Nanoprecise customers to identity this asset in their internal system, and it will make Nanoprecise integration with customer's software and APIs possible"
                                placement="right-start"
                              >
                                <Info
                                  style={{
                                    marginRight: "10px",
                                    cursor: "pointer",
                                    fontSize: "30px",
                                  }}
                                />
                              </Tooltip>
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-last-name"
                              type="text"
                              onChange={this.handleChange("externalId")}
                              disabled={this.state.readOnly}
                            />
                          </FormGroup>
                        </Col>

                        <Col lg="12">
                          {
                            <Button
                              className="right"
                              color="success"
                              onClick={() => this.handleSave()}
                              size="md"
                              disabled={this.state.saving}
                            >
                              {this.state.saving ? (
                                <i className="fas fa-spinner fa-pulse"></i>
                              ) : (
                                "Save"
                              )}
                            </Button>
                          }

                          {!this.state.saving && (
                            <Button
                              className="right cmr-10"
                              color="danger"
                              onClick={() => this.setState({ list: true })}
                              size="md"
                            >
                              Cancel
                            </Button>
                          )}
                        </Col>
                      </Row>
                    </div>
                  </CardBody>
                )}
              </Card>
            </div>
          </Row>
        </Container>

        <Dialog
          maxWidth="xl"
          onClose={this.handleDemoClose}
          aria-labelledby="customized-dialog-title"
          open={this.state.demoOpen}
        >
          <DialogTitle>
            <div className="d-flex justify-content-between">
              <div>
                <h5 className="font-weight-bold">Upload user file</h5>
              </div>
            </div>
          </DialogTitle>

          <DialogContent dividers>
            <Input
              className="csv-input"
              id="input-username"
              type="file"
              accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              onChange={this.handleFileInput}
            />

            <Button
              style={{ float: "right", marginTop: "10px", marginRight: "5px" }}
              color="warning"
              size="sm"
              onClick={this.handleSkip}
            >
              skip
            </Button>

            <Button
              style={{ float: "right", marginTop: "10px", marginRight: "10px" }}
              color="success"
              size="sm"
              onClick={this.importCSV}
            >
              upload
            </Button>
            <Button
              style={{ float: "right", marginTop: "10px" }}
              className="right cmr-10"
              color="danger"
              onClick={this.handleDemoClose}
              size="sm"
            >
              Cancel
            </Button>
          </DialogContent>
        </Dialog>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  organizationList: _.isEmpty(state.organization.companyData)
    ? null
    : state.organization.companyData,
});

const mapDispatchToProps = (dispatch) => {
  return {
    fetchOrganizationsList: () => dispatch(fetchOrganizationsList()),
    fetchNotificationCodes: (roleId) =>
      dispatch(fetchNotificationCodes(roleId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Organizations);
